import React from 'react'
import styles from './BlogAuthor.module.css'

export default function BlogAuthor({ name }) {
  const lorraineBio = (
    <div>
      Lorraine Roberte is a{' '}
      <a
        rel="noopener noreferrer"
        target="_blank"
        href="http://craftywriting.com?utm_source=makerkit_blog"
      >
        freelance writer for hire
      </a>{' '}
      who offers blogging and article writing services. Her specialities include
      creating personal finance and digital marketing content for businesses and
      startups. She uses her expert knowledge, skills, and experience to draft
      content that gets attention on social media and visibility on search
      engines.
    </div>
  )
  const sanjBio = null

  const mapping = {
    lorraine: lorraineBio,
    sanj: sanjBio,
  }

  const content = mapping[name]

  if (!content) {
    return null
  }

  return (
    <div className={styles.root}>
      <div className={styles.header}>Written by</div>
      {content}
    </div>
  )
}
