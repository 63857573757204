import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../layouts/index'
import styles from './blog-post.module.css'
import Helmet from 'react-helmet'
import BlogAuthor from '../components/BlogAuthor'
import { Twitter, Facebook, Linkedin, Whatsapp } from 'react-social-sharing'
import { BlogCTA } from '../components/BlogCTA'

export default ({ data }) => {
  const post = data.markdownRemark
  const frontmatter = post.frontmatter
  const postUrl = `https://suggested.co/{frontmatter.slug}`

  return (
    <Layout>
      <Helmet>
        <title>{post.frontmatter.title} | Suggested blog</title>
        <meta name="description" content={frontmatter.description} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:description" content={frontmatter.description} />
        <meta name="twitter:title" content={frontmatter.title} />
        <meta name="twitter:site" content="@suggestedco" />
        <meta name="twitter:image" content={'https://suggested.co' + frontmatter.thumbnail.publicURL} />
        <meta name="twitter:creator" content="@sanjeevan84" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={frontmatter.title} />
        <meta property="og:description" content={frontmatter.description} />
        <meta
          property="og:url"
          content={'https://suggested.co' + frontmatter.slug}
        />
        <meta property="og:site_name" content="Sanj Ambalavanar" />
        <meta
          property="og:image"
          content={'https://suggested.co' + frontmatter.thumbnail.publicURL}
        />
      </Helmet>
      <div className={styles.root}>
        <h1 className={styles.title}>{post.frontmatter.title}</h1>
        <div
          className={styles.content}
          dangerouslySetInnerHTML={{ __html: post.html }}
        />

        <div className={styles.cta}>
          <BlogCTA />
        </div>

        <div className={styles.shareLinks}>
          <Twitter link={postUrl} small={true} style={{}} />
          <Facebook link={postUrl} small={true} />
          <Linkedin link={postUrl} small={true} />
          <Whatsapp link={postUrl} small={true} />
        </div>

        <div className={styles.author}>
          <BlogAuthor name={frontmatter.author} />
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
        slug
        thumbnail {
          publicURL
        }
        author
      }
    }
  }
`
