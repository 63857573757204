import React from 'react'
import styles from './BlogCTA.module.css'

export const BlogCTA = () => {
  return (
    <div className={styles.root}>
      <a className={styles.name} href="https://suggested.co">
        Suggested
      </a>{' '}
      is lightweight tool for collecting, and managing feature requests from
      your customers to build products that your users actually want. Designed
      for product managers{' '}
      <span aria-label="Heart" role="img">
        💖
      </span>
      <div>
        <a href="https://app.suggested.co/register" className="btn btn-primary">
          Get started for free
        </a>
      </div>
    </div>
  )
}
